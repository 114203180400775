// import React, { useState, useRef, useEffect } from "react";
import Home from "./components/Home";
// import About from "./components/About";
// import InstaFb from "./components/InstaFb";
// import YouTube from "./components/YouTube";
// import Travel from "./components/Travel";
// import Property from "./components/Property";

function App() {
  // const [showTravel, setShowTravel] = useState(false);
  // const [showProperty, setShowProperty] = useState(false);

  // // Create refs for the Travel and Property components
  // const travelRef = useRef(null);
  // const propertyRef = useRef(null);

  // const handleTravelClick = () => {
  //   // console.log('Travel clicked');
  //   setShowTravel(true);
  //   setShowProperty(false);
  //   // window.open('/travel', '_blank');
  // };

  // const handlePropertyClick = () => {
  //   // console.log('Property clicked');
  //   setShowTravel(false);
  //   setShowProperty(true);
  //   // window.open('/property', '_blank');
  // };

  // useEffect(() => {
  //   if (showTravel && travelRef.current) {
  //     travelRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [showTravel]);

  // useEffect(() => {
  //   if (showProperty && propertyRef.current) {
  //     propertyRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [showProperty]);

  // useEffect(() => {
  //   setShowTravel(false);
  //   setShowProperty(false);
  // }, []);

  return (
    <div className="App">
      <Home />
      {/* <About
        onTravelClick={handleTravelClick}
        onPropertyClick={handlePropertyClick}
      />
      <div ref={travelRef}>{showTravel && <Travel />}</div>
      <div ref={propertyRef}>{showProperty && <Property />}</div>
      <InstaFb />
      <YouTube /> */}
      {/* <Travel /> <-- These 2 components unused like this!! Leave commented out - for testing visibility 
      <Property /> */}
    </div>

    // <Router>
    //   <div className="App">
    //     <Routes>
    //     {/* Define routes using the Route component */}
    //       <Route exact path="/" component={Home} />
    //       <Route path="/about" component={About} />
    //       <Route path="/instafb" component={InstaFb} />
    //       <Route path="/youtube" component={YouTube} />
    //       <Route path="/travel" component={Travel} />
    //       <Route path="/property" component={Property} />
    //     </Routes>
    //   </div>
    // </Router>
  );
}

export default App;
