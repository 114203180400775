import "../css/Home.css";
import myPhoto from "../background.jpg";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import EmailIcon from "@mui/icons-material/Email";
import myLogo from "../tpi-logo-2.png";

function Home() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Smooth scrolling behavior
    });
  };

  const scrollToAbout = () => {
    const nextComponent = document.getElementById("About");
    if (nextComponent) {
      nextComponent.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToInstaFb = () => {
    const nextComponent = document.getElementById("InstaFb");
    if (nextComponent) {
      nextComponent.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToYouTube = () => {
    const nextComponent = document.getElementById("YouTube");
    if (nextComponent) {
      nextComponent.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleEmailButtonClick = () => {
    window.location.href = "mailto:thetravellinginvestors@gmail.com";
  };

  return (
    <div className="Home">
      <div className="Landing-Page">
        <div className="Content-Coming-Soon">CONTENT COMING SOON</div>
        <header className="Home-header">
          <img src={myPhoto} className="Home-photo" alt="" />
          <div className="Nav-container">
            {" "}
            {/* Container for the nav */}
            <nav className="Home-nav">
              <button className="Nav-button" onClick={scrollToTop}>
                <HomeIcon style={{ marginTop: "6.5px", fontSize: "26px" }} />
              </button>
              <button className="Nav-button" onClick={scrollToAbout}>
                <InfoIcon style={{ marginTop: "7.5px", fontSize: "26px" }} />
              </button>
              <button className="Nav-button" onClick={scrollToInstaFb}>
                <InstagramIcon
                  style={{ marginTop: "7.5px", fontSize: "26px" }}
                />
              </button>
              <button className="Nav-button" onClick={scrollToYouTube}>
                <YouTubeIcon style={{ marginTop: "8px", fontSize: "26px" }} />
              </button>
              <button className="Nav-button" onClick={handleEmailButtonClick}>
                <div>
                  <EmailIcon style={{ marginTop: "8px", fontSize: "26px" }} />
                </div>
              </button>
            </nav>
          </div>
          <div className="Home-text-container">
            {/* <h1 className='Main-title'>The Travelling Property Investors</h1> */}
            <img src={myLogo} className="Home-logo" alt="" />
            <h2 className="Intro-h2">The BACKWARDS Life</h2>
            <p className="home-p">
              Travel well, Invest smart.
              <br />
              Let us inspire you.
              <br />
            </p>
            <a
              className="Home-link"
              href="mailto:thetravellinginvestors@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Let's chat!
            </a>
          </div>
        </header>
      </div>
    </div>
  );
}

export default Home;
